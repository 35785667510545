export default {
    getDetailData: (state) => {
        return state.productData
    },
    getProductSubtype: (state) => (type) => {

        // let type = null;

        const subtype = state.productData.sub_type

        const hasSizeArray = ['band-ring', 'anniversary-ring', 'full-eternity-ring', 'toi-et-moi-ring','engagement-ring','hues-ring','soiree-ring']

        const hasChainLengthArray = ['tennis-bracelet', 'tennis-necklace', 'solitaire-necklace','fashion-necklace']
        const hasBackTypeArray = ['stud-earring','fashion-earring']


        if( hasSizeArray.includes( subtype ) ){

            return 'size'
        }
        else if(hasChainLengthArray.includes(subtype) ){

            return 'chain_length'
        }
        else if ( hasBackTypeArray.includes(subtype) ) {

            return 'back_type'
        }


       
        // if(this.detailData.sub_type === 'couple-bands-ring'){
        //     data.her_size = this.$store.getters.getCurrentActive('her_size')?.value
        //     data.his_size = this.$store.getters.getCurrentActive('his_size')?.value
        // }

        
    },
    getMetalDropDownList: (state) => (type) => {

        const dropdownList = state.metalCaratDropdownArray

        if(dropdownList && dropdownList.length === 0){
            return []
        }
        const size = state.getCurrentActive('size')

        const availableStock = state.inStockData

		if( availableStock && availableStock.length === 0 ){

			return []
		}
        // console.log('size', dropdownList)
        if( size ){

            const filteredMetal =  dropdownList
            .filter( ( metal ) =>  {
                
                const status =  availableStock.find( (stock) =>  {
                    // console.log(stock.value , size.value, stock.in_stock , stock.weight , metal.weight , stock.metal , metal.metal, stock.value === size.value && stock.weight === metal.weight && stock.metal === metal.metal )
                    return stock.value === size.value && stock.weight === metal.weight && stock.metal === metal.metal
                } )

                // console.log(status,'status', status  )
                return status ? true : false
            })

            return filteredMetal
        }

        return dropdownList
    },
    getInitialMetalCaratList: (state) => (type) =>{
        return state.initialMetalList
    },

    getInStockData: (state) => (type) =>{
        return state.inStockData
    },
    getInitialSizeList: (state) => {
        return state.initialSizeList
    },

    // getFastShipSizeArray: (state) => {

    //     // find active metal and carat and pass on only active size array
    //     const  activeVariations = state.getMetalDropDownList
    //     // console.log('active', activeVariations)

    //     if(! activeVariations)  {
           
    //         return null
    //     }

    //     const active = activeVariations.find( (cv) => cv.selected === 1 )
    // },

    getDropDownList: (state) => (type) =>{
        if(!state.productData){
            return null
        }
        if(type && type === 'metal'){
            return state.productData.metals.filter(metal => metal.availability === 1);
        }
        if(type && type === 'carat'){
            let list
            state.productData.metals.find((item) =>{
                if(item.selected === 1){
                    list = item.carats.filter( ( cv ) => cv.availability === 1 || cv.selected === 1) 
                }
            })
            return list
        }

        if(type && ['back_type', 'size', 'chain_length', 'his_size', 'her_size'].includes(type)){

            
            if( !state.fastShip ){
             

                if(type === 'her_size'){
                    
                    return state.sizeList.size_two
                }

                // check if supplier stock and limit the sizes
                const isSupplierStock = state.productData.validate_size
                
                if( !isSupplierStock ){

                    return state.sizeList.size_one
                }

                // validate sizes
                // get all fasthip options and filter the sizes
                // filter carat and weight from the instock and the we filter the size
                if( state.inStockData ){
                    
                    const metal = state.getCurrentActive('metal')
                    const carat = state.getCurrentActive('carat')

                    const inStockSizes = state.inStockData
                    .filter( (cv) => cv.metal === metal.value && cv.weight === carat.lot_weight  )
                    .map( (cv) => cv.value )

                    const sizeArray = state.sizeList.size_one.filter( cv =>  inStockSizes.includes( cv.value ) )
                    // console.log('getFastShipSize -----------------', sizeArray, inStockSizes)
                    if(sizeArray.length == 1){
                        sizeArray[0].selected = 1
                    }
                    // console.log(sizeArray, 'state.sizeList.size_one 22222')
                    return sizeArray
                }
            } 

            if(state.fastShip){
               
                // validate sizes
                // get all fasthip options and filter the sizes
                // filter carat and weight from the instock and the we filter the size
                // if( state.inStockData ){
                
                    const metal = state.getCurrentActive('metal')
                    const carat = state.getCurrentActive('carat')

                    const inStockSizes = state.inStockData
                    // .filter( (cv) => cv.metal === metal.value && cv.weight === carat.lot_weight  )
                    .map( (cv) => cv.value )

                    const sizeArray = state.sizeList.size_one.filter( cv =>  inStockSizes.includes( cv.value ) )
                    if(sizeArray.length == 1){
                        sizeArray[0].selected = 1
                    }
                    // console.log(sizeArray, 'state.sizeList.size_one 22222')
                    return sizeArray
                // }

            }

            if(type === 'her_size'){
                return state.sizeList.size_two
            }

          

            return state.sizeList.size_one
        }
        return null
    },
    
    getCurrentActive: (state) => (type) => {

        // console.log('getCurrentActive', type)
        if(! state.productData){
            return null
        }

        if(type && type === 'metal'){
            
            return state.productData.metals.find( (cv) => cv.selected === 1 )
        }

        if(type && type === 'carat'){
            
            let metal = state.productData.metals.find((item) => item.selected === 1 )
            
            if( ! metal && ! metal.carats ){

                return null
            }

            const activecarat = metal.carats.find( (cv) =>   cv.selected ===  1  )
                    
            if( !activecarat && metal.carats[0] ){

                metal.carats[0].selected = 1 
                
            }
            return metal.carats.find( (cv) =>   cv.selected ===  1  )
        }


        // console.log('getCurrentActive 2', type)
        if(type && type === 'size'){
            return state.sizeList.size_one.find( (cv) => cv.selected === 1 ? true : false ) ?? null
        }

        if(type && type === 'his_size'){
            return state.sizeList.size_one.find( (cv) => cv.selected === 1 ? true : false ) ?? null
        }

        if(type && type === 'her_size'){
            return state.sizeList.size_two.find( (cv) => cv.selected === 1 ? true : false ) ?? null
        }


        // if(type && type === 'size'){
        //     return state.sizeList.size.find( (cv) => cv.selected === 1 ? true : false ) ?? null
        // }

        // if(type && type === 'back_type'){
        //     return state.sizeList.back_type.find( (cv) => cv.selected === 1 ? true : false ) ?? null
        // }

        // if(type && type === 'chain_length'){
        //     // console.log('type chain length', state.sizeList.chain_length.find( (cv) => cv.selected === 1 ? true : false ) ?? null)
        //     return state.sizeList.chain_length.find( (cv) => cv.selected === 1 ? true : false ) ?? null
        // }

        // if(type && type === 'his_size'){
        //     return state.sizeList.his_size.find( (cv) => cv.selected === 1 ? true : false ) ?? null
        // }

        // if(type && type === 'her_size'){
        //     return state.sizeList.her_size.find( (cv) => cv.selected === 1 ? true : false ) ?? null
        // }
        return null
        
    },
    
    getWebshopSliderImages: (state) =>{
        if(!state.productData){
            return null
        }
     
       
        let color = state.getCurrentActive('metal')
        if(!state.productData.all_images.length){
            return null
        }
        let slickImages = state.productData.all_images.find((item)=> item.color === color.icon) 

        if (slickImages.images !== undefined ) {

            if (slickImages.images.model !== undefined && slickImages.images.model === null) {

                delete slickImages.images.model 
            }

            if (slickImages.images.front !== undefined && slickImages.images.front === null) {

                delete slickImages.images.front 
            }

            if (slickImages.images.left !== undefined && slickImages.images.left === null) {

                delete slickImages.images.left 
            }

            if (slickImages.images.right !== undefined && slickImages.images.right === null) {

                delete slickImages.images.right 
            }

            if (slickImages.images.video !== undefined && slickImages.images.video === null) {

                delete slickImages.images.video 
            }
        }
        if(Object.keys(slickImages.images).length === 0){
            return null
        }
       
        return slickImages.images
    },

    getCurrentActiveAttribute: (state) => (type) => {
        
        if(type === 'her_size'){
            if(state.sizeList.size_two){
                const active = state.sizeList.size_two.find( (cv) => cv.selected === 1)
                if(!active){
                    return null
                }
                return state.sizeList.size_two.find( (cv) => cv.selected === 1)
            }
        }
        else{
            if(state.sizeList.size_one){
                const active = state.sizeList.size_one.find( (cv) => cv.selected === 1)
                if(!active){
                    return null
                }
                return state.sizeList.size_one.find( (cv) => cv.selected === 1)
            }
        }
       
       return null
        
    },

    getAdditionalDetails: (state) =>{
        return state.additionalAttribute?.product_attribute
    }

}