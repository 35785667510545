import { defineStore } from 'pinia'
import actions from './actions.js';
import mutations from './mutations.js';
import getters from './getters.js';


export const shopdetailStore = defineStore('shopdetail', {
    state: () => {
        return {
            productData: false,
            sizeList: {
                size_one: null,
                size_two: null
            },
            additionalAttribute: false,
            fastShip: null,
            inStockData: [],
            metalCaratDropdownArray: [],
        }
    },
    getters: { ...getters},
    actions: {...actions, commitMutation: function( mutationToCall, data ) {

        try {
            mutations[mutationToCall]( this, data )
        } catch (error) {
            console.error( 'SHOP DETAIL MUTATION ERROR - ', mutationToCall, data, error )
            throw error
        }
    }},
    // persist: true,
})
