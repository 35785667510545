export default {
	setDetailData(state, data){
		state.productData 	= data.data
		state.fastShip 		= data.fastShip
	},
	setInStockMetals(state, data){
		
		const availableStock = state.inStockData

		if( availableStock && availableStock.length === 0 ){

			return []
		}
		const ids = [];
		
		const metalArray = []

		// prepare all possible combination
		state.productData.metals.forEach((metal) => {

			metal.carats.forEach( ( carat )=>  {

				const label = `${carat.label.replace('Ct', 'Carat')} | ${metal.label}`

				if ( ids.includes( label )) {
					return
				}

				metalArray.push ({ 
					label: label,
					icon: metal.icon,
					metal: metal.value,
					weight: carat.lot_weight,
					metal_value: metal.value,
					weight_value: carat.value,
					selected:0,
					visibility:0,
					value:metal.value+'_'+carat.value,
					default:0
				});
				ids.push(label); 
			})
		});

		// filter combinations based on in stock data
		const filteredMetal =  metalArray
		.filter( ( metal ) =>  {

			return availableStock.find( (stock) =>  {
				// console.log(stock.in_stock , stock.weight , metal.weight , stock.metal , metal.metal )
				return stock.in_stock && stock.weight === metal.weight && stock.metal === metal.metal
			} )
		})

		if( filteredMetal.length === 0 ){

			return []
		}
		
		const activeMetal = state.productData.metals.find( (mt) => mt.selected === 1 )
		const activeCarat = activeMetal.carats.find( (ct) => ct.selected === 1 )
		let activeOption = null

		if( activeCarat && activeMetal ){

			activeOption = filteredMetal.find( (metal) => {
				return activeCarat.lot_weight === metal.weight && activeMetal.value === metal.metal
			}) 
		}

		if(activeOption){
			activeOption.selected = 1
		}else{
			filteredMetal[0].selected = 1
		}
		
		state.metalCaratDropdownArray = filteredMetal
	},

	setInstockData(state, data){
		// console.log('setInstockData', data)
		state.inStockData =  data
	},

	setSizeData(state, {sizeData, data}){
		
		if(sizeData.sub_type === "couple-bands-ring" ){
			state.sizeList.size_one = sizeData.his_size
			state.sizeList.size_two = sizeData.her_size
		}
		else{
			state.sizeList.size_one = sizeData.sizeArray
		}


		if( data.fastShip && sizeData.sub_type !== "couple-bands-ring" ){

			state.sizeList.size_one.forEach((value, index) => {

				value.selected = 0
			} )
			
		}		
	},
	setActive: (state, data) => {
	
		
		const updateSize = (state, data ) =>{
			if(data.type === 'her_size'){
				state.sizeList.size_two.forEach((cv) => {
					cv.selected = (cv.value === data.value ? 1 : 0);
				});
			}
			else{

				state.sizeList.size_one.forEach((cv) => {
					cv.selected = (cv.value === data.value ? 1 : 0);
				});
			}
		}

		

		if(data.type === 'metal'){
			state.productData.metals.forEach((cv) => {
				cv.selected = (cv.value === data.value ? 1 : 0);
			});
		}
		
		if(data.type === 'carat'){
			const metal = state.productData.metals.find( (cv) => cv.selected === 1 )
			metal.carats.forEach((cv) => {
				cv.selected = (cv.value === data.value ? 1 : 0);
			});
		}
		console.log('data.type', data.type)

		if(['size','back_type', 'chain_length', 'his_size', 'her_size'].includes(data.type)){
			// console.log('abcd', data)

			if( ! state.fastShip ){
				
				return updateSize( state, data )
			}

			// if fast ship then check if user size is available in mt,
			// if not mark first option as selected

			const metal = state.productData.metals.find( cv => cv.selected === 1 )
			const carat = metal.carats.find( (cv) => cv.selected === 1 )
			
			// check if size exists in stock data 
			const stockExists = state.inStockData.find( (cv) => cv.metal === metal.value && cv.weight === carat.lot_weight && cv.value === data.value )
			
			// if does not exists then we mark first metal as selected
			if(! stockExists){

				const stock = state.inStockData.find( (cv) => cv.value === data.value )

				// update active metal ,carat and dropdownw
				state.productData.metals.forEach((cv) => {
					cv.selected = (cv.value === stock.metal ? 1 : 0);
				});

				const metal = state.productData.metals.find( (cv) => cv.selected === 1 )
				metal.carats.forEach((cv) => {
					
					cv.selected = (cv.lot_weight === stock.weight ? 1 : 0);
					// console.log( 'cv', cv.lot_weight , stock.weight , cv,cv.value === stock.weight)
				});

				state.metalCaratDropdownArray.forEach((metal) => {
					// console.log('metalCaratDropdownArray,',metal, stock )
					metal.selected = (metal.metal_value === stock.metal && metal.weight === stock.weight  ? 1 : 0);
				});
				

				// console.log('stoc', stock)
			}



			
			return updateSize(  state, data )
		}
		

		if(data.type === 'metalCaratDropdown'){
			state.metalCaratDropdownArray.forEach((cv) => {
				cv.selected = (cv.value === data.value ? 1 : 0);
			});
			


		}
    },
	resetWebshopDetail(state){
		state.productData = false
	},

	setAdditionalDetails(state, data){
		state.additionalAttribute = data
	},
	
}
